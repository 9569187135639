import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import BlogComponent from "../components/blogcomp"
import Clients from "../components/clients"

const Nosotros = ({ data }) => {
  const piechart = data.piechart
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

      <section className="about-us">
        <div className="heading">
          <h1>Nosotros</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col right">
              <div className="nosotros">
                <h3>P&P Consultores Asesoría Integral S.A.C.</h3>
                <p>
                  Nos dedicamos a brindar soluciones en sistemas de gestión y
                  asesoría legal a las empresas de la actualidad.
                </p>
                <p>
                  Nuestro objetivo es poder dar soluciones a las nuevas
                  exigencias del mercado que necesitan empresas competitivas
                  tanto en la calidad de sus productos o servicios hasta la
                  confiabilidad en sus trabajadores.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="mision">
                <h3>Misión</h3>
                <p>
                  Somos una empresa dedicada a brindar servicios de
                  asesoramiento, capacitación, consultoría e implementación de
                  sistemas de gestión internacional, brindando un soporte legal
                  continuo y necesario según el régimen societario del cliente.
                </p>
              </div>
              <div className="mision">
                <h3>Visión</h3>
                <p>
                  Lograr ser una compañía exitosa en brindar soluciones
                  empresariales de gestión que permitan mejorar la
                  competitividad de nuestros clientes. Lograr captar el 15% del
                  mercado de consultoría y asesoría legal en el año 2022.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <h3>Valores</h3>
              <p>
                Toda actividad y relación que ejecute la empresa debe girar en
                los valores y principios de la organización, los cuales son:{" "}
              </p>
              <ul>
                <li>Honestidad</li>
                <li>Puntualidad</li>
                <li>Eficiencia</li>
                <li>Calidad</li>
                <li>Compromiso</li>
                <li>Pasión</li>
              </ul>
              {/*    <img src={valores} alt="valores" /> */}
            </div>
            <div className="col" style={{ justifyContent: "center" }}>
              <Img fluid={piechart.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </section>

      <section name="clientes" className="clientes">
        <div className="heading">
          <h1>Clientes</h1>
          <h3>Ellos ya confían en nosotros</h3>
        </div>
        <div className="container">
          <Clients />
        </div>
      </section>
      <BlogComponent />
    </Layout>
  )
}
export const query = graphql`
  {
    piechart: file(absolutePath: { glob: "**/src/images/piechart.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vision: file(absolutePath: { glob: "**/src/images/vision.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mision: file(absolutePath: { glob: "**/src/images/mision.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Nosotros
